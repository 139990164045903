const Blog = () => {
    return (
        <div className='flex flex-col items-center'>
            <div>
                <h1 className='self-center text-2xl text-lime-500'>here i will tell you all my darkest secrets</h1>
            </div>
        </div>
    )
}

export default Blog